import React from "react";
import { twMerge } from "tailwind-merge";
import { IconClock } from "@tabler/icons-react";
import { GroupedProduct } from "../api/getGroupedProducts.ts";
import GradiantSeparator from "./GradiantSeparator.tsx";

const GroupedProductListItem = ({ product }: { product: GroupedProduct[] }) => {
  const productBrand = product[0]?.brand;
  const productName = product[0]?.name;
  const image = product[0]?.images?.[0];

  const handleDivClick = (option: GroupedProduct) => {
    // Open a new webview or tab with the specified URL
    window.open(option.deeplink, '_blank', 'noopener,noreferrer');
  };

  return (
    <div
      className={twMerge(
        "flex flex-col gap-2 rounded-md bg-white px-4 py-1")}>
      <img className="h-24 w-full bg-transparent object-contain gap-2" src={image} alt="" />
      <div className="flex grow flex-col">
        <div className="w-full py-2">
          <div className="line-clamp-1 text-start text-xs font-normal text-text-light">
            {productBrand}
          </div>
          <div className="line-clamp-2 text-start text-sm font-bold text-text">{productName}</div>
        </div>
        <div className="flex flex-col">
          {product.map((option, index) => (
            <div className="" style={{ cursor: 'pointer'}} onClick={() => handleDivClick(option)}>
              <div key={index} className="flex w-full items-center justify-between">
                <img className="h-5 rounded" src={option.platform.icon} alt=""></img>
                <div className="text-m font-semibold text-text">
                  {option.mrp !== option.offer_price ? (
                    <>
                      <span className="text-xs font-normal text-text-light line-through">
                        ₹{option.mrp}
                      </span>
                      <span> </span>{option.available?
                      <span className="text-m font-bold">₹{option.offer_price}</span>:
                      <span className="text-m text-text-light font-bold">₹{option.offer_price}</span>}
                    </>
                  ) : (
                    option.available?
                      <span className="text-m font-bold">₹{option.offer_price}</span>:
                      <span className="text-m text-text-light font-bold">₹{option.offer_price}</span>
                  )}
                </div>
              </div>
              {/* <div>{option.id}</div> */}
              <div key={index} className="flex w-full items-center justify-between">
                <div className="text-sm text-text-light">
                  {option.quantity}
                </div>
                {option.available ? (
                  option.platform.sla ? (
                    <div className="flex flex-row items-center gap-1">
                      <IconClock size={12} stroke={2} className="text-text-light" />
                      <span className="text-xs text-text-light">{option.platform.sla}</span>
                    </div>
                  ) : <span />
                ) : (
                  <span className="bg-text rounded px-2 py-1 text-bg text-3xs font-bold">Out Of Stock</span>
                )}
              </div>
              <div className="py-1">
              {index < product.length - 1 ? <GradiantSeparator /> : null}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default GroupedProductListItem;
